import { NC_ENV } from 'shared/constants/cookies';
import { cookies } from 'shared/utils/storage/cookies';

export interface ConfigNavigation {
  SPA?: true;
  baseUrl?: string;
  newUpdatePaymentMethod?: boolean;
  newLandlineURL?: boolean;
  showDevTools?: boolean;
  alwaysShowLeftNav?: boolean;
  newMobileURL?: boolean;
  newBroadbandURL?: boolean;
  newTvURL?: boolean;
}

export interface ConfigInvoices {
  monthsToShow?: number;
}

export interface ConfigMobile {
  host?: string;
  freeDataInfo?: {
    startDate: string;
    endDate: string;
  };
}

export interface Odin {
  host?: string;
  dockerHost?: string;
  xcareHost?: string;
  segment?: string;
  bcInstance?: string;
}

export interface MicroServices {
  host?: string;
  version?: number;
}

export interface Config {
  debug?: boolean;
  port?: number;
  odin?: Odin;
  microServices?: MicroServices;
  tracking?: 'prod' | 'dev';
  navigation?: ConfigNavigation;
  invoices?: ConfigInvoices;
  mobile?: ConfigMobile;
  redirectIfNotLoggedIn?: boolean;
  loadMockServiceWorker?: boolean;
  SENTRY_INSTANCE?: string;
  netcracker?: {
    host: string;
    graphQlEndpoint: string;
  };
  ciam?: {
    host?: string;
  };
}

// sets values for test environment
const stagingConfig: Config = {
  odin: {
    bcInstance: 'bcpp',
  },
  navigation: {
    baseUrl: '',
    newUpdatePaymentMethod: true,
    newLandlineURL: true,
    showDevTools: true,
    alwaysShowLeftNav: true,
  },
  tracking: 'dev',
  redirectIfNotLoggedIn: true,
  loadMockServiceWorker: true,
  SENTRY_INSTANCE: 'https://26ccf4c1d6074096b25a7cfd7873e742@o341752.ingest.sentry.io/5648723',
};
// test environment end

// sets values for prod environment
const productionConfig: Config = {
  odin: {
    bcInstance: 'bcprod',
  },
  navigation: {
    baseUrl: 'https://ny-selvbetjening.yousee.dk',
    newUpdatePaymentMethod: false,
    newLandlineURL: true,
    showDevTools: false,
    alwaysShowLeftNav: false,
  },
  tracking: 'prod',
  redirectIfNotLoggedIn: true,
  loadMockServiceWorker: false,
  SENTRY_INSTANCE: 'https://26ccf4c1d6074096b25a7cfd7873e742@o341752.ingest.sentry.io/5648723',
  netcracker: {
    host: 'https://yousee.dk',
    graphQlEndpoint: '/shop/api/graphql-server/graphql',
  },
  ciam: {
    host: 'https://login.yousee.dk',
  },
};
// prod environment end

export interface ConfigProps {
  sets: {
    default: Config;
    staging: Config;
    production: Config;
  };
}

const getTestEnvironment = (ncEnv: string) => {
  const allowedTest2Envs = ['e2e01', 'pps02', 'pps01', 'ad01', 'staging01'];

  return allowedTest2Envs.some((elem) => ncEnv.includes(elem));
};

export const config: ConfigProps = {
  sets: {
    default: {
      odin: {
        xcareHost: import.meta.env.PUBLIC_ODIN_XCARE_URL,
        segment: 'yousee',
        host: import.meta.env.PUBLIC_ODIN_URL,
        dockerHost: import.meta.env.PUBLIC_ODIN_DOCKER_URL,
      },
      invoices: {
        monthsToShow: 18,
      },

      navigation: {
        SPA: true,
        newMobileURL: true,
        newBroadbandURL: true,
        newTvURL: true,
        newLandlineURL: true,
      },

      microServices: {
        host:
          cookies.get(NC_ENV, true) && getTestEnvironment(cookies.get(NC_ENV, true))
            ? 'https://test2.api.swiss.np-nuuazure.dk'
            : import.meta.env.PUBLIC_MICROSERVICES_URL,
        version: 1,
      },

      mobile: {
        host: import.meta.env.PUBLIC_MOBILE_API_URL,
        freeDataInfo: {
          startDate: '2022-06-01',
          endDate: '2022-09-01',
        },
      },
      netcracker: {
        host:
          (cookies.get(NC_ENV, true) && cookies.get(NC_ENV, true).slice(0, -1)) ||
          'https://nd-cloud-portal-uat01-bss.non-prod02.nc.nuuday.nu',
        graphQlEndpoint: '/shop/api/graphql-server/graphql',
      },
      ciam: {
        host:
          cookies.get(NC_ENV, true) && getTestEnvironment(cookies.get(NC_ENV, true))
            ? 'https://test2.ciam.nuuday.dk'
            : 'https://test.ciam.nuuday.dk',
      },
    },
    staging: { ...stagingConfig },
    production: { ...productionConfig },
  },
};
